<template>
  <div>
    <div style="padding: 15px 0;display: flex;justify-content: space-between;">
      <el-button-group>
        <slot>
          <template v-for="(button ,bottonIndex) in ((buttonLists || []).length === 0? baseButtonLists :buttonLists)">
            <el-button type="primary"
                       :plain="button.plain === true || (!button.callback && $route.name !== priexRoute+button.name)"
                       :disabled="button.disabled === true"
                       @click="()=>{
                         button.callback ? button.callback() : $handleRoute({...(button.params || $route.params)},priexRoute+(button.route || button.name))
                       }"
                       :key="bottonIndex"
            >{{ button.title }}</el-button>
          </template>
        </slot>
        <el-button type="primary"
                   @click="$handleRoute({...$route.params},$route.name)"
                   v-if="otherNames.indexOf($route.name) === -1 && ($route.meta || {}).title"
        >{{ ($route.meta || {}).title}}</el-button>
      </el-button-group>
      <el-button-group>
        <slot name="right">
          <template v-for="(button ,bottonIndex) in rightButtonLists">
            <el-button type="primary"
                       :plain="button.plain === true || (!button.callback && $route.name !== priexRoute+button.name)"
                       :disabled="button.disabled === true"
                       @click="()=>{
                         button.callback ? button.callback() : $handleRoute({...(button.params || $route.params)},priexRoute+(button.route || button.name))
                       }"
                       :key="bottonIndex"
            >{{ button.title }}</el-button>
          </template>
        </slot>
      </el-button-group>
      <!-- <el-button
          type="primary"
          size="medium"
          @click="
            $handleRoute(
              { course_list_id: $route.query.list_id },
             'teachPlanCourseTaskCreate'
            )
          "
          >创建作业</el-button
        > -->
    </div>
    <diy-card :is-card-judge="true">
      <router-view v-bind="$attrs"></router-view>
    </diy-card>
  </div>
</template>

<script>
export default {
  props:{
    priexRoute:{
      type:String,
      default(){return 'teaching';},
      validator(value) {
        return ['teachPlan', 'teaching'].includes(value)
      }
    },
    buttonLists:{
      type:Array,
      default(){return null;}
    },
  },
  data(){
    return {
      baseButtonLists:[],
      otherNames:[],
      rightButtonLists:[],
    }
  },
  watch:{
    "$props":{
      immediate:true,
      deep:true,
      handler(v){
        this.$nextTick(()=>{
          this.initButtons(this.buttonLists);
        })
      }
    }
  },
  methods:{
    routeUpdate(){
      this.initButtons(this.buttonLists);
    },
    initButtons(buttons){
      let{course_id} = this.$route.params
      buttons = buttons || [
        {name:'CourseBase',route:course_id > 0 ? 'CourseEdit' : 'CourseCreate',title:'基本信息'},
        {name:'CourseStudy',route:'CourseStudy',title:'学生管理',params:{course_id},disabled :!(course_id > 0)},
        {name:'CourseTeach',route:'CourseTeach',title:'教师管理',params:{course_id},disabled :!(course_id > 0)},
        {name:'CourseCont',route:'CourseCont',title:'课程内容',params:{course_id},disabled :!(course_id > 0)},
        {name:'CourseTask',route:'CourseTask',title:'作业列表',params:{course_id},disabled :!(course_id > 0)},
      ]
      let otherNames = [];
      for (let i = 0; i < buttons.length; i++) {
        if(buttons[i].route){
          otherNames.push(this.priexRoute+buttons[i].route);
        }
        if(buttons[i].name && buttons[i].route !== buttons[i].name){
          let _index = buttons[i].name.indexOf('CourseBase');
          if(_index > -1 && buttons[i].name.substr(_index) === 'CourseBase'){
            otherNames.push(this.priexRoute+buttons[i].name+'Edit');
            if(this.$route.name === this.priexRoute+buttons[i].name+'Edit'){
              buttons[i].name = buttons[i].name+'Edit';
            }else{
              otherNames.push(this.priexRoute+buttons[i].name);
            }
          }else{
            otherNames.push(this.priexRoute+buttons[i].name);
          }
        }
      }
      this.otherNames = otherNames;
      this.baseButtonLists = buttons;
      return buttons;
    }
  }
}
</script>
<style scoped>

</style>